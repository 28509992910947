import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const FloatingBanner = () => {
  // Hook pour détecter la taille de l'écran
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    
    // Nettoyage de l'écouteur d'événements
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Style du conteneur principal (la div flottante)
  const containerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    maxHeight: '64rem',
    backgroundColor: '#fff',
    boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    zIndex: 1000,
  };

  // Style pour centrer et agencer le contenu interne
  const innerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1200px',
  };

  const imageStyle = {
    height: '40px',
    marginRight: '1rem',
  };

  const textStyle = {
    flex: 1,
    textAlign: 'center',
    fontSize: '1rem',
  };

  // Styles pour les boutons
  const buttonStyle = {
    marginLeft: '1rem',
    padding: '0.5rem 1rem',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#00a8a8',
    color: '#fff',
    borderRadius: '4px',
  };

  const buttonStyle2 = {
    ...buttonStyle,
    backgroundColor: '#ffd700',
  };

  return ReactDOM.createPortal(
    <div style={containerStyle}>
      <div style={innerStyle}>
        {isMobile ? (
          // En mode mobile, on affiche uniquement le bouton "Réserver un rendez-vous"
          <a href="https://outlook.office.com/bookwithme/user/d0b2081c9a2146838442708b433fa4cf@jelam.fr?anonymous&ep=plink" style={buttonStyle2} target='_blank'>Réserver un rendez-vous</a>
        ) : (
          // En mode desktop, on affiche l'image, le texte et les deux boutons
          <>
            <img src="./assets/img/logo.png" alt="Logo" style={imageStyle} />
            <div style={textStyle}>
              Appelez-nous au <strong>01 80 86 41 53</strong> pour démarrer
            </div>
            <a href='#prices' style={buttonStyle}>Voir le prix</a>
            <a href="https://outlook.office.com/bookwithme/user/d0b2081c9a2146838442708b433fa4cf@jelam.fr?anonymous&ep=plink" style={buttonStyle2} target='_blank'>Réserver un rendez-vous</a>
          </>
        )}
      </div>
    </div>,
    document.body
  );
};

export default FloatingBanner;