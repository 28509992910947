import React from 'react'
import MetaTags from '../components/MetaTags';

function lpdesignsprint() {
    return (
        <>
            <MetaTags
                title='Ne ratez pas votre prochaine innovation'
                description="Découvrez comment nous vous accompagnons vers la réussite de votre projet innovant."
                image='assets/img/favicon.png'
                name='Jelam'
            />
            <div className='stg-container'>

                {/* Hero Section */}
                <section>
                    <div className="bringer-hero-block bringer-hero-type05">
                        <div className="stg-row">
                            <div className="stg-col-6">
                                <div className="bringer-block bringer-title-block" data-appear="fade-right" data-unload="fade-left">

                                    {/* Title Cell */}
                                    {/* Title */}
                                    <h1 className="bringer-page-title">Ne ratez pas votre <b className="jelam-special-word-ss-bg jelam-spacing-5">Prochaine</b> <b className="jelam-special-word jelam-spacing-5" >Innovation</b></h1>
                                    <h2 className="stg-top-gap stg-bottom-gap-l"></h2>
                                    {/* Description */}
                                    <p className="bringer-large-text">Ensemble, nous définissons avec vous <span className="bringer-accent">le MVP*</span> parfaitement adapté à vos enjeux, puis, étape par étape, nous le transformons en succès rentable.</p>
                                    <p><span className="bringer-accent">*</span> Minimum Viable Product : Fonctionnalités clés & minimales pour toucher le marché.</p>
                                </div>

                                <div className="stg-row">
                                    <div className="stg-col-8 stg-tp-col-8 stg-m-col-8">
                                        {/* Small Image */}
                                        <img src="assets/img/inner-pages/design-sprint.jpg" alt="MVP raté" data-appear="fade-up" data-delay="100" data-unload="zoom-out" />
                                    </div>
                                    <div className="stg-col-4 stg-tp-col-4 stg-m-col-4">
                                        {/* Button */}
                                        <a href="./contact" className="bringer-square-button" data-appear="zoom-in" data-delay="200" data-unload="zoom-out">
                                            <span className="bringer-icon bringer-icon-explore"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* Main Image Column */}
                            <div className="stg-col-6 bringer-hero-media-wrap stg-tp-hide stg-m-hide" data-bg-src="assets/img/inner-pages/handshake.jpg" data-appear="fade-left" data-unload="fade-right">
                                {/* Media */}
                                <img src="assets/img/inner-pages/mvpfailure.jpg" alt="Bring your Product" data-appear="fade-up" data-delay="100" />
                            </div>
                        </div>
                    </div>{/* .bringer-hero-block */}
                </section>

                {/* Section: How We Make */}
                <section className="divider-top">
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap-l">
                        <div className="stg-col-6 stg-offset-3 align-center">
                            <h2 data-split-appear="fade-up" data-unload="fade-up">Cela vous dit quelque chose ?</h2>
                        </div>
                    </div>
                    {/* Services Grid */}
                    <div className="bringer-grid-3cols bringer-tp-grid-2cols bringer-tp-stretch-last-item" data-stagger-appear="fade-up" data-delay="100" data-stagger-unload="fade-up">
                        {/* Item 01 */}
                        <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                            <h3>Incertitude<span className="bringer-accent">.</span></h3>
                            <p className="bringer-large-text bringer-tp-normal-text" >Je me demande si cette idée est bonne et répond vraiment à un besoin.</p>
                        </div>
                        {/* Item 02 */}
                        <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                            <h3>Résultats décevants<span className="bringer-accent">.</span></h3>
                            <p className="bringer-large-text bringer-tp-normal-text" >Je suis frustré de ne pas voir les résultats escomptés malgré tous mes efforts.</p>
                        </div>
                        {/* Item 03 */}
                        <div className="bringer-block stg-aspect-square stg-vertical-space-between">
                            <h3>Marché saturé<span className="bringer-accent">.</span></h3>
                            <p className="bringer-large-text" >Je ne sais pas comment faire en sorte que mon produit se démarque de la concurrence.</p>
                        </div>
                    </div>
                </section>

                {/* Section: Steps */}
                <section className="backlight-top">
                    <div className="stg-bottom-gap-l">
                        <h2 data-appear="fade-up" data-unload="fade-up">2 actions pour réussir</h2>
                    </div>
                    <div className="stg-row stg-large-gap stg-valign-middle stg-bottom-gap-l stg-tp-bottom-gap-xl stg-tp-column-reverse stg-m-bottom-gap-l stg-m-normal-gap">
                        <div className="stg-col-6" data-appear="fade-right" data-unload="fade-left">
                            <h2>Faire un “Design Sprint”</h2>
                            <p className="bringer-large-text">Objectif : Lever les incertitudes et définir, ensemble, un MVP et un plan d'action alignés avec les enjeux d'entreprise, marché et utilisateurs. </p>
                            <p><strong>Pourquoi :</strong> Challenger sa vision par un regard extérieur et des propositions concrètes d'experts mobilisés autour de vous et vos équipes pendant 4 jours.</p>
                            {/* <p>We believe in the power of creativity. We help creative professionals create a strong online presence that showcases their work and tells their story.</p> */}
                        </div>
                        <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-appear="fade-left" data-unload="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/workshop.jpg" alt="Conception" width="960" height="960" />
                            </div>
                        </div>
                    </div>{/* .stg-row */}

                    <div className="stg-row stg-large-gap stg-valign-middle stg-m-normal-gap">
                        <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-appear="fade-right" data-delay="50" data-unload="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/training.jpg" alt="Formation" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6" data-appear="fade-left" data-delay="50" data-unload="fade-right">
                            <h2>Former et accompagner</h2>
                            <p className="bringer-large-text">Objectif : Structurer et outiller vos équipes pour piloter efficacement le projet innovant, afin de garantir le succès du lancement et sa pérennité.</p>
                            <p><strong>Pourquoi :</strong> Après avoir défini le MVP, il est crucial que vos équipes deviennent autonomes et formées pour suivre le plan d’action, assurer un pilotage optimal, et réussir la mise en œuvre du projet.</p>
                            <div className="align-right">
                                <a href="./contact" className="bringer-arrow-link">Commençons dès maintenant !</a>
                            </div>
                        </div>
                    </div>{/* .stg-row */}
                </section>

                {/* Section: Why Choose Us */}
            <section>
                <div className="stg-row stg-large-gap">
                    <div className="stg-col-6 stg-tp-bottom-gap-l" data-unload="fade-left">
                        <div className="bringer-sticky-block">
                            <h2>Pourquoi nous choisir ?</h2>
                            <p className="bringer-large-text">Nous tirons le meilleur parti du dynamisme des Startups et de l'expérience des PME, ETI, Grands Groupes pour concevoir des solutions innovantes avec pragmatisme et réalisme.</p>
                            <div className="align-right">
                                <a href="/home" className="bringer-arrow-link">Découvrir JELAM</a>
                            </div>
                        </div>
                    </div>
                    <div className="stg-col-6">
                        <div className="bringer-grid-1col stg-normal-gap" data-stagger-appear="zoom-in" data-stagger-unload="zoom-out" data-threshold="0.5" data-stagger-delay="150">
                            <div className="bringer-block"> 
                                <h4>Nous nous concentrons sur <span className="bringer-accent">la transformation</span>, pas seulement comme un changement, mais <span className="bringer-accent">comme une opportunité de croissance.</span></h4>
                                <p>Nous sommes avant tout des partenaires engagés à vos côtés. Nous croyons que chaque entreprise, peu importe sa taille, mérite d’évoluer avec confiance dans un environnement en pleine transformation. Notre équipe vous accompagne, pas à pas, dans la création de produits innovants conçus pour dépasser les standards du marché.</p>
                            </div>{/* .bringer-block */}

                            {/* Card Item */}
                            <div className="bringer-block">
                                <h4>Nous ne faisons pas que nous aligner aux besoins des utilisateurs, nous harmonisons <span className="bringer-accent">marché, usage et technologie.</span></h4>
                                <p>Nous comprenons que vos besoins sont uniques, tout comme votre marché. Notre approche pragmatique vous garantit des solutions alignées sur les exigences de votre marché, sans dépenses inutiles, et optimisées pour maximiser votre retour sur investissement.</p>
                            </div>{/* .bringer-block */}
                                                        
                            {/* Card Item */}
                            <div className="bringer-block">
                                <h4>Nous faisons plus que réaliser votre produit, <span className="bringer-accent">nous alignons chaque réalisation sur vos objectifs</span> pour un impact mesurable<span className="bringer-accent">.</span></h4>
                                <p>Grâce à un modèle flexible, nous avançons étape par étape pour affiner chaque solution, tout en nous assurant qu'elle réponde à vos objectifs commerciaux. Notre engagement repose sur une relation de confiance à long terme, où nous réussissons ensemble, en évitant les coûts excessifs.</p>
                            </div>{/* .bringer-block */}
                        </div>{/* .bringer-grid */}
                    </div>
                </div>
            </section>

                {/* Section: Prices */}
                <section className="divider-both tp-is-fullwidth tp-is-stretched">
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap" data-unload="fade-up">
                        <div className="stg-col-8 stg-offset-2 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center">
                                <h2 data-split-appear="fade-up">Offre</h2>
                            </div>
                        </div>
                    </div>
                    <div className="stg-row stg-bottom-gap-l" data-unload="fade-up" data-delay="100">
                        <div className="stg-col-6 stg-offset-3 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center stg-bottom-gap">
                                <p className="bringer-large-text" data-appear="fade-up" data-delay="100">
                                Augmentez vos chances 🍀 de succès avec notre Design Sprint tout en réduisant les risques. 📈
                                </p>
                            </div>
                            <div>
                                {/* Carousel Item 01 */}
                                <div className="bringer-block bringer-price-table swiper-slide">
                                    <h3>Design Sprint<span className="bringer-accent">.</span></h3>
                                    <p>4 jours d'accompagnement stratégique par nos experts pour lever vos incertitudes et réussir.</p>
                                    {/* <div className="bringer-price-wrapper">
                                        <h2>27 500 €<span className="bringer-accent">*</span></h2>
                                        <div><span className="bringer-accent">*</span>20 000€ pour toutes les entreprises de moins de 10 salariés.</div>
                                        <div className="bringer-label">Acompte de 50% à la commande</div>
                                    </div> */}
                                    <div className="bringer-label bringer-highlight">Les livrables</div>
                                    <ul className="bringer-marked-list">
                                        <li>Un Vision Board</li>
                                        <li>Une stratégie Go-To-Market</li>
                                        <li>Un prévisionnel financier</li>
                                        <li>Un prototype testable</li>
                                        <li>Un cahier des charges</li>
                                    </ul>
                                    <a href="https://outlook.office.com/bookwithme/user/d0b2081c9a2146838442708b433fa4cf%40jelam.fr/meetingtype/3eb7fb8b-b980-4821-a948-8de68faee06e?anonymous" target='_blank' className="bringer-button is-fullwidth">Donnons vie à votre projet</a>
                                    <p className='stg-top-gap align-center'>ou</p>
                                    <h4 className='stg-top-gap align-center'>Appelez-nous au <span className="bringer-accent">01 80 86 41 53</span></h4>
                                </div>{/* .bringer-carousel-card */}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section: FAQ */}
                <section>
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap-l">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-split-appear="fade-up" data-unload="fade-up">Vous avez des questions ?</h2>
                                <p className="bringer-large-text" data-appear="fade-up" data-delay="100" data-unload="fade-up">Voici quelques questions qui reviennent fréquemment</p>
                            </div>
                        </div>
                    </div>
                    {/* FAQ List */}
                    <div className="bringer-faq-list">
                        {/* FAQ Item 01 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>01.</sup>
                                    Qu'est-ce qu'un Design Sprint ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Le Design Sprint de JELAM est une méthodologie collaborative sur 4 jours, durant laquelle nous réunissons une équipe d'experts (Innovation, Technologie, Produit, Finance, Marketing & Sales, UX/UI design) pour travailler avec vous et vos équipes. L’objectif est de définir un MVP (Minimum Viable Product) aligné avec vos enjeux stratégiques et de construire un plan d'action concret pour sa réalisation. C'est une méthode rapide et structurée qui permet de passer de l'idée à un plan clair d'exécution en très peu de temps.</p>
                            </div>
                        </div>
                        {/* FAQ Item 02 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>02.</sup>
                                    Comment faites-vous pour m'aider à construire mon MVP ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Chez JELAM, nous aidons à construire votre MVP en mobilisant une équipe pluridisciplinaire qui vous accompagne tout au long du processus. Nous écoutons vos besoins, analysons les retours de vos utilisateurs, effectuons des études techniques, marché et financière, et priorisons les fonctionnalités clés pour atteindre le marché rapidement et efficacement. L’objectif est de définir le produit minimal mais essentiel, prêt à être testé et développé.</p>
                            </div>
                        </div>
                        {/* FAQ Item 03 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>03.</sup>
                                    Est-ce que ça va me prendre du temps ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Le Design Sprint de JELAM est conçu pour être rapide et efficace. En 4 jours, vous participez à des ateliers structurés avec nos experts, mais nous gérons la majeure partie des travaux pour vous. Vous devrez participer activement à certains moments clés, comme la définition des priorités ou la validation des prototypes, mais le reste est pris en charge par nos équipes. Cela vous permet de conserver du temps pour gérer vos autres activités.</p>
                            </div>
                        </div>
                        {/* FAQ Item 04 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>04.</sup>
                                    Qu'est-ce que j'aurais à la fin du Design Sprint ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>À l'issue du Design Sprint, vous recevrez un ensemble de livrables concrets pour avancer rapidement sur votre projet :</p>
                                <p>
                                    <ul>
                                        <li>Un Vision Board qui synthétise les concepts et priorités</li>
                                        <li>Une stratégie Go-To-Market</li>
                                        <li>Un prévisionnel financier basé sur le MVP</li>
                                        <li>Un prototype testable avec des retours d'utilisateurs</li>
                                        <li>Un plan d’action pour la réalisation du MVP</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        {/* FAQ Item 05 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">Service</span>
                                <h4>
                                    <sup>05.</sup>
                                    Que se passe-t-il après le Design Sprint ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Après le Design Sprint, vous avez deux options avec JELAM pour poursuivre efficacement votre projet :</p>
                                <p><ol>
                                    <li>Former vos équipes : Nous proposons des modules de formation sur les outils et méthodes nécessaires pour piloter le projet en interne. Cela permet à vos équipes d’être autonomes dans le suivi et la mise en œuvre du MVP, en renforçant leurs compétences en gestion de projets innovants.</li>
                                    <li>Accompagnement personnalisé : Si vous préférez un soutien plus étendu, nous réunissons une équipe technique sur plusieurs cycles d’innovation structurés. Chaque cycle de 8 semaines permet de développer, tester et itérer rapidement des fonctionnalités du MVP, en vous garantissant des livrables concrets à chaque étape. Cette approche réduit les risques tout en maintenant un développement agile et flexible.</li>
                                </ol></p>
                                <p>Quelle que soit l'option choisie, JELAM reste à vos côtés pour assurer la réussite et la pérennité de votre projet.</p>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default lpdesignsprint