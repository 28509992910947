import React from 'react'
import MetaTags from '../components/MetaTags';
import FloatingBanner from '../components/FloatingBanner';

function lpmarketfit() {
    return (
        <>
            <MetaTags
                title='Startup|Trouvons votre Product Market Fit|Clarifions votre Positionnement'
                description="Startup Early Stage | Vision difficile à expliquer ? Nous clarifions votre positionnement pour trouver votre Product-Market Fit et booster vos conversions."
                image='assets/img/favicon.png'
                name='Jelam'
            />
            <div className='stg-container'>

                {/* Hero Section */}
                <section>
                    <div className="bringer-hero-block bringer-hero-type06">
                        <div className="bringer-bento-grid stg-bottom-gap">
                            <div className="is-large bringer-masked-block" data-appear="fade-right" data-unload="fade-left">
                                <div className="bringer-bento-hero-media bringer-masked-media" data-bg-src="assets/img/inner-pages/incompris.jpg">
                                    {/* Title */}
                                    <h1 className="bringer-page-title">Vision difficile à expliquer ?</h1>

                                    {/* Description */}
                                    <p className="bringer-highlight">Créons des offres claires qui captent l'attention et convertissent.</p>
                                    <div className="align-left" data-appear="fade-up" data-unload="fade-up" data-delay="100">
                                        <a href="https://outlook.office.com/bookwithme/user/d0b2081c9a2146838442708b433fa4cf@jelam.fr?anonymous&ep=plink" class="bringer-button" target='_blank'>Réservez 30 min d'échange</a>
                                    </div>
                                </div>
                                <div className="bringer-masked-content at-bottom-right">
                                    <a href="#services" className="bringer-square-button" data-appear="zoom-in" data-delay="100">
                                        <span className="bringer-icon bringer-icon-arrow-down"></span>
                                    </a>
                                </div>
                            </div>
                            <div className="is-medium bringer-block stg-vertical-space-between" data-appear="fade-left" data-delay="300" data-unload="fade-right">
                                <h2 className='jelam-special-word-ss-bg'>Trouvons vite votre Market Fit 🚀.</h2>
                                <p>Vous êtes une startup ? Nous vous apportons un soutien opérationnel pour construire un produit qui séduit le marché même avec des ressources limitées. Nous sommes des faiseurs, pas des conseillers. Ensemble séduisons vos clients et vos investisseurs.</p>
                            </div>
                            <div className="is-small" data-appear="zoom-out" data-delay="100" data-unload="zoom-out">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/before-jelam.jpg" alt="Before us" width="1200" height="1200" />
                            </div>
                            <div className="is-small" data-appear="zoom-out" data-delay="200" data-unload="zoom-out">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/after-jelam.jpg" alt="After us" width="1200" height="1200" />
                            </div>

                        </div>
                    </div>{/* .bringer-hero-block */}
                </section>
                {/* Intégration du composant flottant en bas de page */}
                <FloatingBanner />
                {/* Section: Services */}
                <section>
                    {/* Section Title */}
                    <div className="stg-row bringer-section-title">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-appear="fade-up" data-unload="fade-up">Votre message ne convertit pas ?</h2>
                                <h3 className="bringer-large-text is-short" data-appear="fade-up" data-unload="fade-up" data-delay="100">On sait pourquoi</h3>
                            </div>
                        </div>
                    </div>
                    {/* Section Content */}
                    <div className="stg-row stg-valign-middle stg-large-gap">
                        <div className="stg-col-4 bringer-tp-grid-2cols bringer-m-grid-1col">
                            {/* Icon Box */}
                            <div className="bringer-image-box" data-appear="fade-right" data-unload="fade-left">
                                <div className="bringer-image-box-head">
                                    <img src="assets/img/inner-pages/market-fit.jpg" alt="Market-Fit" />
                                    <h5 className='title-colored'>Market-Fit<span className="bringer-accent">.</span></h5>
                                </div>
                                <div className="bringer-image-box-content">
                                    <p>Votre startup galère à trouver son Product-Market Fit ? Vous vous trompez de problème, donc de réponse.</p>
                                </div>
                            </div>{/* .bringer-image-box */}
                            {/* Icon Box */}
                            <div className="bringer-image-box stg-m-bottom-gap-l" data-appear="fade-right" data-unload="fade-left" data-delay="100">
                                <div className="bringer-image-box-head">
                                    <img src="assets/img/inner-pages/Positionnement.jpg" alt="Mauvais positionnement" />
                                    <h5 className='title-colored'>Positionnement<span className="bringer-accent">.</span></h5>
                                </div>
                                <div className="bringer-image-box-content">
                                    <p>Votre positionnement change à chaque pitch ? Un message qui parle à tout le monde, donc à personne.</p>
                                </div>
                            </div>{/* .bringer-image-box */}
                        </div>
                        <div className="stg-col-4 stg-tp-col-8 stg-tp-offset-2 stg-tp-bottom-gap-l" data-appear="zoom-out" data-unload="zoom-out" data-delay="150">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/we-know.jpg" alt="Pourquoi " width="800" height="1200" />
                            </div>
                        </div>
                        <div className="stg-col-4 bringer-tp-grid-2cols bringer-m-grid-1col">
                            {/* Icon Box */}
                            <div className="bringer-image-box" data-appear="fade-left" data-unload="fade-right">
                                <div className="bringer-image-box-head">
                                    <img src="assets/img/inner-pages/confusing-message.jpg" alt="Message pas clair" />
                                    <h5 className='title-colored'>Message flou<span className="bringer-accent">.</span></h5>
                                </div>
                                <div className="bringer-image-box-content">
                                    <p>Vos leads ne comprennent pas votre offre ? On le sait vous êtes visionnaires mais... Trop d’idées = Pas assez de clarté.</p>
                                </div>
                            </div>{/* .bringer-image-box */}
                            {/* Icon Box */}
                            <div className="bringer-image-box" data-appear="fade-left" data-unload="fade-right" data-delay="100">
                                <div className="bringer-image-box-head">
                                    <img src="assets/img/inner-pages/direction.jpg" alt="Décision difficile" />
                                    <h5 className='title-colored'>Manque de data<span className="bringer-accent">.</span></h5>
                                </div>
                                <div className="bringer-image-box-content">
                                    <p>Vous ne savez pas par quoi commencer ou si il faut pivoter ? Vous avancez sans vous retourner. Vous manquez de feedback concret du marché.</p>
                                </div>
                            </div>{/* .bringer-image-box */}
                        </div>
                    </div>
                </section>

                {/* Section: Details */}
                <section className="backlight-both" id="services">
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap-l">
                        <div className="stg-col-7">
                            <h2 data-split-appear="fade-up" data-unload="fade-up">Et si on réparait ça ?</h2>
                            <h3 data-split-appear="fade-up" data-unload="fade-up">Trouvons votre Product-Market Fit. Clarifions votre positionnement.</h3>
                            <p className="bringer-large-text is-short" data-appear="fade-up" data-unload="fade-up" data-delay="100"><span className='jelam-special-word'>Nous sommes des faiseurs. Pas des conseillers. On co-développe avec vous.</span><br /> Marketing, design, tech, sales : tout est aligné.</p>
                        </div>
                        <div className="stg-col-5"></div>
                    </div>
                    {/* Details 01 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
                        <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/audit.jpg" alt="Diagnostic" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
                            <span className="bringer-label">Étape 01</span>
                            <h4 className='title-colored'>Audit .</h4>
                            <p>Nous comprenons votre vision, challengeons et enrichissons vos idées (Vite & Bien).</p>
                        </div>
                    </div>
                    {/* Details 02 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
                        <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/methode.jpg" alt="Organiser" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
                            <span className="bringer-label">Étape 02</span>
                            <h4 className='title-colored'>Méthode .</h4>
                            <p>Nous mettons en place votre boucle de feedback (Concret & Constructif).</p>
                        </div>
                        <div className="stg-col-3">{/* Empty Column */}</div>
                    </div>
                    {/* Details 03 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-m-bottom-gap-l" data-unload="fade-left">
                        <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/produire.jpg" alt="Développer" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
                            <span className="bringer-label">Étape 03</span>
                            <h4 className='title-colored'>Produire .</h4>
                            <p>Nous créons des assets qui captent, convainquent et convertissent (landing pages, prototypes, MVP).</p>
                        </div>
                    </div>
                    {/* Details 04 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
                        <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/mesurer.jpg" alt="Indicateurs" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
                            <span className="bringer-label">Étape 04</span>
                            <h4 className='title-colored'>Mesure .</h4>
                            <p>Nous mesurons l’impact avec des indicateurs simples et actionnables (sans se noyer dans les chiffres).</p>
                        </div>
                        <div className="stg-col-3">{/* Empty Column */}</div>
                    </div>
                    {/* Details 05 Row */}
                    <div className="stg-row stg-valign-middle" data-unload="fade-left">
                        <div className="stg-col-3 stg-offset-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-right">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/apprendre.jpg" alt="Feedback" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-right" data-delay="100">
                            <span className="bringer-label">Étape 05</span>
                            <h4 className='title-colored'>Apprendre .</h4>
                            <p>Nous tirons des leçons et itérons (agile, pertinent, sans gaspillage)</p>
                        </div>
                    </div>
                    {/* Details 04 Row */}
                    <div className="stg-row stg-bottom-gap stg-valign-middle stg-tp-row-reverse stg-m-bottom-gap-l" data-unload="fade-right">
                        <div className="stg-col-3 stg-tp-col-6 stg-m-bottom-gap" data-appear="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/financement.jpg" alt="Levée de fonds" width="960" height="960" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-tp-col-6" data-appear="fade-left" data-delay="100">
                            <span className="bringer-label">Étape 06</span>
                            <h4 className='title-colored'>Financement .</h4>
                            <p>Nous identifions et valorisons les preuves de traction marché (focus sur le potentiel de croissance).</p>
                        </div>
                        <div className="stg-col-3">{/* Empty Column */}</div>
                    </div>
                </section>

                {/* Section: Prices */}
                <section className="divider-both tp-is-fullwidth tp-is-stretched" id="prices">
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap" data-unload="fade-up">
                        <div className="stg-col-8 stg-offset-2 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center">
                                <h2 data-split-appear="fade-up">Prêt à convaincre ?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="stg-row stg-bottom-gap-l" data-unload="fade-up" data-delay="100">
                        <div className="stg-col-6 stg-offset-3 stg-tp-col-6 stg-tp-offset-3">
                            <div className="align-center">
                                <p className="bringer-large-text" data-appear="fade-up" data-delay="100">
                                    Pas de théorie. On apporte du concret pour rencontrer vos clients !
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Price Table Carousel */}
                    <div className="bringer-grid-3cols" data-m-count="3.5" data-appear="fade-up" data-delay="200" data-unload="fade-up">
                        {/* <div className="swiper-wrapper"> */}
                        {/* Carousel Item 01 */}
                        <div className="bringer-block bringer-price-table">
                            <h6>Pack Positionnement<span className="bringer-accent">.</span></h6>
                            <p>Votre message, testé sur le terrain à moindre coût.</p>
                            <div className="bringer-price-wrapper">
                                <div className="bringer-label">Pour démarrer</div>
                                <div className="bringer-label">À partir de</div>
                                <h2>10 000 €</h2>
                            </div>
                            <div className="bringer-label bringer-highlight align-center"><br /><br /></div>
                            <div className="bringer-label bringer-highlight">Ce qui est compris</div>
                            <ul className="bringer-marked-list">
                                <li>3 ateliers.</li>
                                <li>Positionnement et messages clés.</li>
                                <li>Votre Landing page.</li>
                                <li>Indicateurs clés.</li>
                            </ul>
                            <a href="/contact" className="bringer-button is-fullwidth">Appelez-nous<br /><span className='bringer-large-text'>01 80 86 41 53</span></a>
                        </div>{/* .bringer-carousel-card */}
                        {/* Carousel Item 02 */}
                        <div className="bringer-block bringer-price-table" data-centered="1">
                            <h6>Pack Product Market Fit<span className="bringer-accent">.</span> </h6>
                            <p>On montre, on récolte des feedback et on apprend.</p>
                            <div className="bringer-price-wrapper">
                                <div className="bringer-label">Inclus le Pack Positionnement</div>
                                <div className="bringer-label">À Partir de</div>
                                <h2>30 000 €</h2>
                            </div>
                            <div className="bringer-label bringer-highlight align-center">🌟 CII - Récupérez 20% en crédit d'impôt</div>
                            <div className="bringer-label bringer-highlight">Ce qui est compris</div>
                            <ul className="bringer-marked-list">
                                <li>3 ateliers supplémentaires.</li>
                                <li>Fonctions et indicateurs clés.</li>
                                <li>Un max de prototypes.</li>
                                <li>Analyse des feedback.</li>
                            </ul>
                            <a href="/contact" className="bringer-button is-fullwidth">Appelez-nous<br /><span className='bringer-large-text'>01 80 86 41 53</span></a>
                        </div>{/* .bringer-carousel-card */}

                        {/* Carousel Item 03 */}
                        <div className="bringer-block bringer-price-table">
                            <h6>Pack Go To Market<span className="bringer-accent">.</span></h6>
                            <p>On va jusqu'au produit. Testé. Amélioré. Validé.</p>
                            <div className="bringer-price-wrapper">
                                <div className="bringer-label">Inclus le Pack Product Market Fit</div>
                                <div className="bringer-label">À Partir de</div>
                                <h2>65 000 €</h2>
                            </div>
                            <div className="bringer-label bringer-highlight align-center">🌟 CII - Récupérez 20% en crédit d'impôt</div>
                            <div className="bringer-label bringer-highlight">Ce qui est compris</div>
                            <ul className="bringer-marked-list">
                                <li>6 ateliers supplémentaires.</li>
                                <li>Votre MVP en production.</li>
                                <li>Indicateurs clés.</li>
                                <li>1 atelier Go-To-Market</li>
                            </ul>
                            <a href="/contact" className="bringer-button is-fullwidth">Appelez-nous<br /><span className='bringer-large-text'>01 80 86 41 53</span></a>
                        </div>{/* .bringer-carousel-card */}

                        {/* </div> */}
                        {/* Pagination */}
                        {/* <div className="swiper-pagination bringer-dots"></div> */}
                    </div>{/* .bringer-carousel */}
                </section>

                {/* Section: Intro */}
                <section>
                    <div className="stg-row stg-large-gap stg-m-normal-gap">
                        <div className="stg-col-6 stg-tp-bottom-gap-l stg-m-bottom-gap" data-appear="fade-right" data-unload="fade-left">
                            <div className="bringer-parallax-media">
                                <img className="bringer-lazy" src="img/null.png" data-src="assets/img/inner-pages/choose-us.jpg" alt="Notre approche" width="1200" height="1200" />
                            </div>
                        </div>
                        <div className="stg-col-6 stg-vertical-space-between" data-appear="fade-left" data-unload="fade-right">
                            <div>
                                <h2>Pourquoi choisir notre approche ?</h2>
                                <p className="bringer-large-text">
                                    <ul>
                                        <li><strong>Pas de bla-bla.</strong> Juste des résultats mesurables.</li>
                                        <li><strong>Co-développement opérationnel.</strong> À vos côtés dans tous les aspects.</li>
                                        <li><strong>Expérience solide.</strong> On a vu ce qui marche (et ce qui foire).</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="tp-align-right">
                                <a href="https://outlook.office.com/bookwithme/user/d0b2081c9a2146838442708b433fa4cf@jelam.fr?anonymous&ep=plink" className="bringer-icon-link" target='_blank'>
                                    <div className="bringer-icon-wrap">
                                        <i className="bringer-icon bringer-icon-explore"></i>
                                    </div>
                                    <div className="bringer-icon-link-content">
                                        <h6>
                                            Prenons
                                            <br />
                                            Rendez-vous
                                        </h6>
                                        <span className="bringer-label">Présentez votre startup</span>
                                    </div>
                                </a>{/* .bringer-icon-link */}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section: FAQ */}
                <section>
                    {/* Section Title */}
                    <div className="stg-row stg-bottom-gap-l">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h2 data-split-appear="fade-up" data-unload="fade-up">Vous avez encore des questions ?</h2>
                                <p className="bringer-large-text" data-appear="fade-up" data-delay="100" data-unload="fade-up">Voici quelques-unes des questions que nous entendons le plus souvent:</p>
                            </div>
                        </div>
                    </div>
                    {/* FAQ List */}
                    <div className="bringer-faq-list">
                        {/* FAQ Item 01 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>01.</sup>
                                    Que faites-vous exactement ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Nous sommes bien plus qu’un simple prestataire technique. Nous agissons comme un bras droit opérationnel multi-casquette, en mobilisant des expertises variées : CTO, CPO, CSO, CMO et autres profils stratégiques. Notre approche est holistique : nous intervenons sur la conception du produit, son développement, son positionnement, sa promotion et jusqu’à sa vente. Grâce à nos ressources partagées, nous vous apportons une vision globale et des actions concrètes à chaque étape de votre croissance, avec un impact mesurable.</p>
                            </div>
                        </div>
                        {/* FAQ Item 02 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>02.</sup>
                                    Quelle est la durée de l'accompagnement ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Notre accompagnement peut durer de 2 semaines à 6 mois, en fonction de vos besoins spécifiques, de votre stade d'avancement et de la complexité de votre projet.</p>
                            </div>
                        </div>
                        {/* FAQ Item 03 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>03.</sup>
                                    Comment démarrer l'accompagnement ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>C'est simple ! Réservez un échange de 30 minutes ou appelez nous directement. Nous discuterons de vos besoins, des objectifs à atteindre, et nous définirons ensemble la meilleure approche pour démarrer.</p>
                            </div>
                        </div>

                        {/* FAQ Item 04 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>04.</sup>
                                    Que faire si j'ai déjà une landing page ou un MVP ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Parfait ! Nous commençons par analyser en profondeur ce que vous avez déjà appris pour détecter ce qui coince et voir si il faut pivoter. À partir de là, nous reprenons la boucle de feedback afin d'identifier les clés de succès. Puis nous collaborons avec vous pour améliorer le produit afin de maximiser son impact sur le marché.</p>
                            </div>
                        </div>

                        {/* FAQ Item 05 */}
                        <div className="bringer-toggles-item bringer-block" data-appear="fade-up" data-unload="fade-up">
                            <div className="bringer-toggles-item--title">
                                <span className="bringer-label">General</span>
                                <h4>
                                    <sup>05.</sup>
                                    Je n'ai pas le budget. Pouvez-vous m'aider ?
                                </h4>
                            </div>
                            <div className="bringer-toggles-item--content">
                                <p>Nous comprenons que le budget peut être un facteur clé. Nous pouvons explorer différentes options pour adapter notre accompagnement à vos contraintes : ajustement de la portée de la mission, priorisation des actions à fort impact, ou mise en place d’un plan en plusieurs phases. Nous pouvons également étudier ensemble les opportunités de financement adaptées à votre projet. Notre objectif est de maximiser la valeur créée, même avec des ressources limitées. N'hésitez pas à nous en parler, nous trouverons des solutions ensemble.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section: CTA Form */}
                <section data-padding="bottom">
                    <div className="bringer-masked-cta bringer-masked-block" data-unload="fade-down">
                        <form className="bringer-contact-form is-short bringer-masked-media" data-appear="fade-up">
                            <div className="bringer-form-content bringer-cta-form">
                                <div className="bringer-cta-form-content" data-appear="fade-up" data-delay="100">
                                    <div className="bringer-cta-title">Démarrons dès maintenant.</div>
                                    <label className='bringer-large-text'>Appelez-nous au 01 80 86 41 53</label>
                                </div>
                            </div>
                        </form>
                        <div className="bringer-masked-cta-content bringer-masked-content at-top-right">
                            <p className="bringer-large-text" data-appear="fade-down">Prêt à clarifier votre offre ? ✨</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default lpmarketfit